import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class VacacionesService {
	constructor(private http: HttpClient, private authSvc: AuthService, private projectSvc: ProjectService, private helpersSvc: HelpersService) {}

	getVacaciones(id, l, o) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/check/" + id + "/" + l + "/" + o;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSolicitudesBoss(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/aprobar/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSolicitudesPendientes(id, l, o) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/pendientes/" + id + "/" + l + "/" + o;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDias(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/days/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	postSolicitud(id, body) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/post/" + id;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getSolicitudConcreta(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/getSolicitud/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	getListaTrabajadores(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/listaTrabajadores/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
	putModificarEstado(id, body) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/put/" + id;
			this.http.put(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//INCIDENCIAS

	postIncidencia(body) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/new";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getUserCurrentIncidence(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/current/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getIncidencias(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getIncidenciasPag(id, l, o) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/pag/" + id + "/" + l + "/" + o;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//Calendario

	getCalendario(jefe, m, a) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/getCalendar/" + m + "/" + a + "/" + jefe;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCalendarioPerson(person, m, a, check) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/getCalendarPerson/" + m + "/" + a + "/" + person + "/" + check;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getCancelarVacacciones(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/cancelar/" + id;
			this.http.put(url, {}, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	//Festivos
	getFestivosNac() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/festivos";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getFestiveDaysDel(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/festivosDel/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getFestiveDaysLoc(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/festivosLoc/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDiasFestivosAllByPerson(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/getDiasFestivosAllByPerson/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getDelegacion(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/delegacion/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getLocalidad(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/persons/localidad/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getFestivosAll(personId) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/vacation/festivos/" + personId;

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getIncidenciasoss(id) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/colaboradores/" + id;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getIncidenciasossPag(id, l, o) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/incidencias/colaboradores/pag/" + id + "/" + l + "/" + o;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}

	getAllVacaciones(limit: number, page: number, filtros: any) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
				params: null,
			};
			options.params = new HttpParams();

			if (filtros.estado != null && filtros.estado !== "") options.params = options.params.set("estado", filtros.estado);
			if (filtros.periodo != null && filtros.estado !== "") options.params = options.params.set("periodo", filtros.periodo);

			if (filtros.personId != null && filtros.personId !== "") options.params = options.params.set("personId", filtros.personId);
			if (filtros.fecha_min != null && filtros.fecha_min !== "") options.params = options.params.set("fecha_min", filtros.fecha_min);
			if (filtros.fecha_max != null && filtros.fecha_max !== "") options.params = options.params.set("fecha_max", filtros.fecha_max);

			const url = environment.urlDomain + "/api/v1/vacation/getAllSol/" + limit + "/" + page;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				}
			);
		});
	}
}
